import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Layout, FooterOne } from "gatsby-theme-layout";
import { NotFound } from "gatsby-theme-container";

const ErrorNotFound = ({ data }) => (
    <Layout>
        <SEO pageName="404" />
        <NotFound />
        <FooterOne data={{ ...data.footer, ...data?.contact }} />
    </Layout>
);

export const query = graphql`
    query NotFoundQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
    }
`;
ErrorNotFound.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default ErrorNotFound;
